<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">예치금종류</div>
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                  v-model="searchConditions.dpmnyKind"
                  :dataSource="searchOptions.dpmnyKindOptions"
                  :allowFiltering="false"
                  :fields="commonCodeFields"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">예치금구분</div>
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                  v-model="searchConditions.dpmnyDiv"
                  :dataSource="searchOptions.dpmnyDivOptions"
                  :allowFiltering="false"
                  :fields="commonCodeFields"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  type="lookup-condition"
                  v-model="searchDateRange"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">회원명/핸드폰 뒤 4자리/비고</div>
            <ul class="content">
              <li class="item">
                <input-text v-model="searchConditions.searchData" />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">입금확인</div>
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="radio"
                        v-model="searchConditions.confirmFlag"
                        :value="null"
                      />
                      <i></i>
                      <div class="label">전체</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="radio"
                        v-model="searchConditions.confirmFlag"
                        :value="true"
                      />
                      <i></i>
                      <div class="label">확인</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="radio"
                        v-model="searchConditions.confirmFlag"
                        :value="false"
                      />
                      <i></i>
                      <div class="label">미확인</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
             @click.native="onDepositMoneyStatusViewClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <section class="article-section">
          <div class="section-caption">
            <div class="caption-navigation">
              <ejs-tab
                ref="dpmnyDivTap"
                :items="dpmnyDivTapList"
                :showCloseButton="false"
                heightAdjustMode="Auto"
                overflowMode="Popup"
                @selected="tabSelected"
              />
            </div>
          </div>
          <div class="section-body">
            <ReportView
              ref="dpmnyStatusReportViewComponent"
              :isPopup="false"
              :style="[
                {
                  display: dpmnyDivTap === 'STATUS' ? 'block' : 'none',
                },
              ]"
            />
            <ReportView
              ref="dpmnyIncomReportViewComponent"
              :isPopup="false"
              :style="[
                {
                  display: dpmnyDivTap === 'INCOM' ? 'block' : 'none',
                },
              ]"
            />
            <ReportView
              ref="dpmnyReturnReportViewComponent"
              :isPopup="false"
              :style="[
                {
                  display: dpmnyDivTap === 'RETURN' ? 'block' : 'none',
                },
              ]"
            />
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputText from "@/components/common/text/InputText";
import InputDateRange from "@/components/common/datetime/InputDateRange";

import ReportView from "@/components/common/report/ReportView";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

import {
  commonCodesGetComName,
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import { mapGetters } from "vuex";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import moment from "moment";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "DepositMoneyStatus",
  components: {
    InputText,
    InputDateRange,
    ReportView,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  watch: {},
  mounted() {
    this.onDepositMoneyStatusViewClicked();
  },
  data() {
    return {
      commonCodeFields: { text: "comName", value: "comCode" },
      searchConditions: {
        dpmnyKind: "ALL",
        dpmnyDiv: "ALL",
        dateFrom: moment().subtract(7, "days").format(DATE_FORMAT_YYYY_MM_DD),
        dateTo: moment().format(DATE_FORMAT_YYYY_MM_DD),
        searchData: null,
        confirmFlag: null,
      },
      searchOptions: {
        dpmnyKindOptions: null,
        dpmnyDivOptions: null,
      },
      dpmnyDivTapList: [
        {
          header: { text: "예치금현황" },
          dpmnyDiv: "STATUS",
        },
        {
          header: { text: "입금신청현황" },
          dpmnyDiv: "INCOM",
        },
        {
          header: { text: "반환신청현황" },
          dpmnyDiv: "RETURN",
        },
      ],
      dpmnyDivTap: "STATUS",
      depositMoneyStatusList: [],
      isEndLoadingStatusList: false,
      isEndLoadingIncomList: false,
      isEndLoadingReturnList: false,
    };
  },
  computed: {
    ...mapGetters(["username"]),
    searchDateRange: {
      get() {
        return {
          from: this.searchConditions.dateFrom,
          to: this.searchConditions.dateTo,
        };
      },
      set(searchDateRange) {
        this.searchConditions.dateFrom = searchDateRange.from;
        this.searchConditions.dateTo = searchDateRange.to;
      },
    },
  },
  async created() {
    this.searchOptions.dpmnyKindOptions = commonCodesGetCommonCode("DPMNY_KIND", true);
    if (
      this.searchOptions.dpmnyKindOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.dpmnyKindOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.dpmnyDivOptions = commonCodesGetCommonCode("DPMNY_DIV", true);
    if (
      this.searchOptions.dpmnyDivOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.dpmnyDivOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
  },
  methods: {
    commonCodesGetComName,
    async onDepositMoneyStatusViewClicked(e, isIgnore = false) {
      if (!this.searchConditions.dateFrom) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["시작일자"])
        );
      }
      if (!this.searchConditions.dateTo) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
      }

      const args = {
        dpmnyKind:
          this.searchConditions.dpmnyKind === "ALL"
            ? null
            : this.searchConditions.dpmnyKind,
        dpmnyDiv:
          this.searchConditions.dpmnyDiv === "ALL"
            ? null
            : this.searchConditions.dpmnyDiv,
        dateFrom: this.searchConditions.dateFrom || null,
        dateTo: this.searchConditions.dateTo || null,
        searchData: this.searchConditions.searchData || null,
        confirmFlag: this.searchConditions.confirmFlag,
        dpmnyDivTap: this.dpmnyDivTap,
      };

      const depositMoneyStatusList = await GolfErpAPI.fetchDepositMoneyStatus(
        args
      );

      let sumStatus = null;
      if (this.dpmnyDivTap === "STATUS") {
        sumStatus = {
          returnIncom: depositMoneyStatusList
            .filter(
              (item) =>
                item.dpmnyKind === "RETURN" &&
                item.dpmnyDiv === "INCOM" &&
                item.confirmFlag
            )
            .reduce((acc, cur) => acc + cur.dpmnyAmt, 0), // 입금
          returnReturn: depositMoneyStatusList
            .filter(
              (item) =>
                item.dpmnyKind === "RETURN" &&
                item.dpmnyDiv === "RETURN" &&
                item.confirmFlag
            )
            .reduce((acc, cur) => acc + cur.dpmnyAmt, 0), // 반환
          returnDeduc: depositMoneyStatusList
            .filter(
              (item) =>
                item.dpmnyKind === "RETURN" &&
                item.dpmnyDiv === "DEDUC" &&
                item.confirmFlag
            )
            .reduce((acc, cur) => acc + cur.dpmnyAmt, 0), // 차감
          exitIncom: depositMoneyStatusList
            .filter(
              (item) =>
                item.dpmnyKind === "EXIT" &&
                item.dpmnyDiv === "INCOM" &&
                item.confirmFlag
            )
            .reduce((acc, cur) => acc + cur.dpmnyAmt, 0), // 소멸예정
          exitExit: depositMoneyStatusList
            .filter(
              (item) =>
                item.dpmnyKind === "EXIT" &&
                item.dpmnyDiv === "EXIT" &&
                item.confirmFlag
            )
            .reduce((acc, cur) => acc + cur.dpmnyAmt, 0), // 소멸완료
        };
      }

      this.depositMoneyStatusList = depositMoneyStatusList.map((item) => {
        if (item?.dpmnyKind) {
          item.dpmnyKind = commonCodesGetComName("DPMNY_KIND", item.dpmnyKind);
        }
        if (item?.dpmnyDiv) {
          item.dpmnyDiv = commonCodesGetComName("DPMNY_DIV", item.dpmnyDiv);
        }
        if (item?.memberDiv) {
          item.memberDiv = commonCodesGetComName("MEMBER_DIV", item.memberDiv);
        }
        if (item?.memberGrade) {
          item.memberGrade = commonCodesGetComName(
            "MEMBER_GRADE",
            item.memberGrade
          );
        }
        if (item?.incomDiv) {
          item.incomDiv = commonCodesGetComName("INCOM_DIV", item.incomDiv);
        }
        if (item?.bankCode) {
          item.bankCode = commonCodesGetComName("BANK_CODE", item.bankCode);
        }
        if (item?.memberNo) {
          item.memberNo = item.memberNo.replace(
            /([0-9]{2})([0-9]{2})([0-9]{4})/,
            "$1-$2-$3"
          );
        }
        if (item?.hpNo) {
          item.hpNo = item.hpNo.replace(
            /([0-9]{3})([0-9]{3,4})([0-9]{4})/,
            "$1-$2-$3"
          );
        }
        return item;
      });

      if (!isIgnore) {
        switch (this.dpmnyDivTap) {
          case "STATUS":
            this.isEndLoadingStatusList = false;
            break;
          case "INCOM":
            this.isEndLoadingIncomList = false;
            break;
          case "RETURN":
            this.isEndLoadingReturnList = false;
            break;
        }
      }

      this.postReport(sumStatus);
    },
    async tabSelected(args) {
      this.dpmnyDivTap = this.dpmnyDivTapList[args.selectedIndex].dpmnyDiv;
      await this.onDepositMoneyStatusViewClicked(null, true);
    },
    postReport(sumStatus) {
      if (
        (this.dpmnyDivTap === "STATUS" && !this.isEndLoadingStatusList) ||
        (this.dpmnyDivTap === "INCOM" && !this.isEndLoadingIncomList) ||
        (this.dpmnyDivTap === "RETURN" && !this.isEndLoadingReturnList)
      ) {
        const searchOptionsList = [
          {
            key: "예치금종류",
            value: commonCodesGetComName(
              "DPMNY_KIND",
              this.searchConditions.dpmnyKind
            ),
          },
          {
            key: "예치금구분",
            value: commonCodesGetComName(
              "DPMNY_DIV",
              this.searchConditions.dpmnyDiv
            ),
          },
          {
            key: "일자",
            value:
              this.searchConditions.dateFrom +
              "~" +
              this.searchConditions.dateTo,
          },
          {
            key: "회원명/핸드폰 뒤 4자리/비고",
            value: this.searchConditions.searchData || null,
          },
          {
            key: "입금확인",
            value:
              this.searchConditions.confirmFlag !== null
                ? this.searchConditions.confirmFlag === true
                  ? "확인"
                  : "미확인"
                : null,
          },
        ];

        const searchOptions = searchOptionsList
          .filter((item) => !!item.value)
          .map((item) => item.key + ": " + item.value)
          .join(", ");

        const reportData = {
          reportJson: {
            jsonData: this.depositMoneyStatusList,
            username: this.username,
            uniqueCode: this.$options.name,
            searchOptions,
            title:
              this.dpmnyDivTap === "STATUS"
                ? null
                : this.dpmnyDivTap === "INCOM"
                ? "예치금 신청 현황"
                : "예치금 반환신청 현황",
            sumStatus,
          },
        };

        switch (this.dpmnyDivTap) {
          case "STATUS":
            this.$refs.dpmnyStatusReportViewComponent.postReport(
              reportData,
              this.$options.name
            );
            break;
          case "INCOM":
            this.$refs.dpmnyIncomReportViewComponent.postReport(
              reportData,
              `${this.$options.name}IncomOrReturn`
            );
            break;
          case "RETURN":
            this.$refs.dpmnyReturnReportViewComponent.postReport(
              reportData,
              `${this.$options.name}IncomOrReturn`
            );
            break;
        }
      }

      switch (this.dpmnyDivTap) {
        case "STATUS":
          this.isEndLoadingStatusList = true;
          break;
        case "INCOM":
          this.isEndLoadingIncomList = true;
          break;
        case "RETURN":
          this.isEndLoadingReturnList = true;
          break;
      }
    },
  },
};
</script>
